<template>
  <div style="background-color:#EFF1F2;height:100%;width:100%">
      <div style="padding:20px;padding-top:4% !important;"> 
        <v-img src="@/assets/gimbook_logo.svg" style="max-width:200px; max-height:300px;" class="mx-auto"></v-img>
      <v-card
        class="mx-auto  mt-2"
        style="padding:10px 50px;min-width:250px;margin-top:6% !important;"
        max-width="580"
        height="400"
      >
      
      <span v-if="this.$store.state.Auth.success"> {{onResetSuccess()}}</span>
        <v-card-text 
          class="text-center pb-2 pt-2" 
          style="margin-bottom:50px;font-size:24px;text-align:center"
        >
          Create a  Password
        <p style="color:red ;margin:3px auto;width:100%;font-size:18px;" v-if="error"> {{error}}</p>
        <p style="color:red ;margin:5px auto;width:100%;font-size:18px;" v-if="this.$store.state.Auth.errorMessage"> {{this.$store.state.Auth.errorMessage}}</p>
        
        </v-card-text>
      
        <form @submit="onSubmit" >
          <div class="input-icons"> 
              <input class="input-field" 
                type="password" 
                v-model="new_password"
                placeholder="Type New Password "
              > 
          </div> 
          <div class="input-icons"> 
              <input class="input-field" 
                type="password" 
                v-model="cnf_password"
                placeholder="Confirm New Password "
              > 
          </div> 
          
          <div class="my-2">
            <v-btn
              x-large
              dark
              type="submit"
              style="background-color:#363740;text-transform:capitalize;font-weight:700;width:100%;margin:auto;font-weight:3px;"
            >
              Reset Password
            </v-btn>
          </div>  
        </form>
      </v-card>
  </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex';

export default {
    name:"PasswordResetConfirm",
    
    data(){

        return {
            uid:this.$route.params['uid'],
            token:this.$route.params['token'],
            new_password:"",
            cnf_password:"",
            error:""
        }
    },
    methods:{
        ...mapActions('Auth',['resetPasswordConfirm']),
        onSubmit(e){
            e.preventDefault();
            let check=true;
            // console.log(this.phone, this.password, 'in login')
            if (this.cnf_password !== this.new_password){
                check=false;
                this.error="Password's Does not match "
                return false
            }
            if(check){
            this.resetPasswordConfirm({'uidb64':this.uid, "token":this.token, "password":this.new_password});
            }
            this.password="";
            
            
        },
        onResetSuccess(){
              this.$router.push({name:'ResetSuccessfull'})
        }  
        

    },
    mounted(){
      this.$store.state.Auth.access=false;
    }  
}
</script>

<style scoped>

        .input-icons i { 
            position: absolute; 
        } 
          
        .input-icons { 
            width: 100%; 
            margin-bottom: 25px; 
            
        } 
          
        .icon { 
            padding: 8px; 
            color: darkgrey;
            background-color:lightgray; 
            min-width: 20px; 
            border:1px solid darkgrey;
            text-align: center; 
        } 
          
        .input-field { 
          border:1px solid grey;
            width: 100%;
            height:55px; 
            font-size:18px;
            padding: 10px; 
           padding-left: 20px; 
            text-align: left; 
        } 
          
        h2 { 
            color: green; 
        } 

</style>